import React from "react";
import profile_photo from "../../assets/images/profile.jpg";
import avatar from "../../assets/icons/profile-icon.png";
import { useAppContext } from "../../Context";
import { useLocalStorage } from "../../hooks";

function Header() {
  const [user] = useLocalStorage("user", {});
  return (
    <div className="py-8 px-10 ">
      <div className="flex justify-end items-center gap-4 opacity-100">
  
      </div>
    </div>
  );
}

export default Header;
