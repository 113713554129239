let apiUrl =
  process.env.NODE_ENV == "development"
    ? "http://localhost:4023/admin"
    : "https://cbot-be.onrender.com/admin";

export const SignIn = async (body) => {
  return await fetch(apiUrl + `/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchUser = async ({ authToken }) => {
  return await fetch(apiUrl + `/fetch-user`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchBots = async (authToken) => {
  return await fetch(apiUrl + `/fetch-bots`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchBot = async (authToken, _id) => {
  return await fetch(apiUrl + `/fetch-bot?_id=${_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchNotifications = async (authToken) => {
  return await fetch(apiUrl + `/fetch-notifications`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchWithdrawal = async (authToken) => {
  return await fetch(apiUrl + `/fetch-withdrawal`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateWithdrawal = async (body, authToken) => {
  return await fetch(apiUrl + `/update-withdrawal`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const ActivateBot = async (body, authToken) => {
  return await fetch(apiUrl + `/activate-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const UpdateBot = async (body, authToken) => {
  return await fetch(apiUrl + `/update-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const MarginCall = async (body, authToken) => {
  return await fetch(apiUrl + `/margin-call`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const AddPerformance = async (body, authToken) => {
  return await fetch(apiUrl + `/add-bot-history`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const DisableBot = async (body, authToken) => {
  return await fetch(apiUrl + `/disable-bot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const NotifyUser = async (body, authToken) => {
  return await fetch(apiUrl + `/notify-user`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchReferral = async ({ authToken }) => {
  return await fetch(apiUrl + `/fetch-referral`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};

export const FetchTransactions = async ({ authToken }) => {
  return await fetch(apiUrl + `/fetch-transactions`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err, "err");
    });
};
