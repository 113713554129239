import React, { useEffect, useState } from "react";
import Wrapper from "./DashboardWrapper";
import avatar from "../../assets/icons/profile-icon.png";
import { useAppContext } from "../../Context";
import { useLoading, useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { SignIn } from "../../API";

function Main() {
  const [user, setUser] = useLocalStorage("user", {});
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    startLoading();
    const res = await SignIn({ email, password });

    if (res?.success) {
      setAuthToken(res.authToken);
      setUser(res.user);
      window.open("/notifications", "_self");
    } else {
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  const handleValidation = () => {
    if (!email) {
      toast.error("Email required", { position: "top-center" });
      return;
    }
    if (!password) {
      toast.error("Password required", { position: "top-center" });
      return;
    }
    handleLogin();
  };

  useEffect(() => {
    if (authToken) {
      //
    } else {
      //window.open("/", "_self");
    }
  }, [authToken]);

  return (
    <Wrapper style={"px-8 py-8 rounded-xl relative bg-[#292C3C]"}>
      <Loader isLoading={isLoading} />
      <div className="">
        <div className="flex flex-col gap-4">
          <input
            placeholder="Enter email"
            className="py-1 px-2 bg-transparent focus:outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            placeholder="Enter password"
            className="py-1 px-2 bg-transparent focus:outline-none"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className=" flex items-center gap-8 mt-12">
            <div
              onClick={handleValidation}
              className="bg-green-dark flex items-center rounded-full px-4 py-2 text-lg cursor-pointer"
            >
              Login
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Main;
