import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Main from "./Main";
import MainWrapper from "../MainWrapper";

function index() {
  return (
    <MainWrapper>
      <Sidebar
        active={{ id: "",  href: "/" }}
      />
      <Header />
      <Main />
    </MainWrapper>
  );
}

export default index;
