import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Profit from "./components/BotManagement/Main";
import Affliate from "./components/Affliate/Main";
import Notifications from "./components/Notifications/Main";
import Details from "./components/BotManagement/Details";
import Performance from "./components/Performance";

function Routing({}) {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Dashboard />}></Route>
        <Route exact path="/notifications" element={<Notifications />}></Route>
        <Route exact path="/bots-management" element={<Profit />}></Route>
        <Route
          exact
          path="/bots-management/details"
          element={<Details />}
        ></Route>
        <Route exact path="/affliate" element={<Affliate />}></Route>
        <Route exact path="/performance" element={<Performance />}></Route>
      </Routes>
    </Router>
  );
}

export default Routing;
