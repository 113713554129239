import React from "react";

function MainWrapper({ children, style }) {
  return (
    <main className={`bg-primary text-sm font-jura sm:text-lg min-h-screen ${style} text-white`}>
      {children}
    </main>
  );
}

export default MainWrapper;
