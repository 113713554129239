import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Routing from "./Routing";
import { AppContextProvider } from "./Context";

function App() {
  return (
    <AppContextProvider>
      <Routing />
      <ToastContainer
        theme="dark"
        hideProgressBar
        closeButton={false}
        icon={false}
      />
    </AppContextProvider>
  );
}

export default App;
