import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import btc_pay from "../../assets/images/btc_pay.png";
import { toast } from "react-toastify";
import { FetchNotifications } from "../../API";

function Main() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");
  const [notifications, setNotifications] = useState([
    { type: "WITHDRAWAL", description: "New payout request", count: 0 },
    { type: "NEW_SIGNUP", description: "New sign ups", count: 0 },
    { type: "NEW_INVOICE", description: "New paid invoice", count: 0 },
  ]);

  const fetchNotifications = async () => {
    startLoading();
    const res = await FetchNotifications(authToken);
    if (res?.success) {
      setNotifications(res.result);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/", "_self");
      }, 3000);
    } else {
      fetchNotifications();
    }
  }, []);

  return (
    <MainWrapper>
      <Sidebar active={{ id: "1", href: "/notifications" }} />
      <Header />
      <Wrapper style={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />

        <div className="bg-[#151924] py-8 px-6 rounded-xl gap-4 flex flex-col">
          {notifications.map((item, index) => (
            <div key={item.type} className="flex items-center justify-between">
              <div className="text-md ">{item.description}</div>
              <div key={`${index}`} className="text-md">
                {item.count}
              </div>
            </div>
          ))}
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Main;
